import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: {

            "education": "Education",
            "proficiency": "Proficiency",
            "contactMe": "Contact Me",
            "seeMyResume": "See my resume",
            "skills": "Skills",
            "workExperiences": "Work Experiences",

            "greeting.title": "Hi all, I'm Baptiste",
            "greeting.subTitle": "An adventurous Software Engineer 🚀 with experience in entrepreneurship, project management and machine learning.",
            "skillSection.title": "Skills",
            "skillSection.subTitle": "I am a full stack engineer, specialized in backend, data science and machine learning",

            "skillSection.skills.1": "Mobile application development, REST & asynchronous API, CI/CD",
            "skillSection.skills.2": "Database exploitation for continuous product improvement",
            "skillSection.skills.3": "Implementation of CI/CD & Integration of third-party tools Firebase/Digital Ocean/Google Cloud",

            "study.iut.schoolName" : "University of Montpellier",
            "study.iut.subHeader" : "University Bachelor of Technology in Computer Science",
            "study.iut.duration" : "Sep 2022 - Aug 2025",
            "study.iut.desc" : "Learning good professional practices in terms of development",

            "workExperience.vstrivia.role": "Entrepreneur",
            "workExperience.vstrivia.company": "VS Trivia",
            "workExperience.vstrivia.date": "Jan 2023 – Current",
            "workExperience.vstrivia.desc": "Complete creation of iPhone/Android applications (design, programming, network). Implemented an API under Python and socket management. Implemented an administrative dashboard in React. Development of the paid offer with IAP.",


            "workExperience.cd30.role": "Work-study Digital Transition Project Manager",
            "workExperience.cd30.company": "Gard Departmental Council",
            "workExperience.cd30.date": "Sep 2023 – Aug 2025",
            "workExperience.cd30.desc": "During this assignment, I was tasked with managing the roll-out at departmental level of an IT solution\n" +
                "developed by the Caisse Nationale de Solidarité pour l'Autonomie (French national solidarity fund for autonomy), aimed at standardizing the assistance process for people losing their autonomy. I was also able to set up decision-making tools using Power BI.",

            "contactInfo.title":"Contact Me ☎️",
            "contactInfo.subTitle": "Discuss a project or just want to say hi? My Inbox is open for all.",

        }
    },
    fr: {
        translation: {
            "education": "Etudes",
            "proficiency": "Efficacité",
            "contactMe": "Me contacter",
            "seeMyResume": "Voir mon CV",
            "skills": "Compétences",
            "workExperiences": "Experiences",

            "greeting.title": "Bienvenue sur mon portfolio",
            "greeting.subTitle": "Un ingénieur logiciel aventureux 🚀 avec une expérience en entrepreneuriat, en gestion de projet et en machine learning",
            "skillSection.title": "Mes compétences",
            "skillSection.subTitle": "Je suis un ingénieur full stack, spécialisé dans le backend, la data science et le machine learning",

            "skillSection.skills.1": "Développement d'application mobile, d'API REST & asynchrone, CI/CD",
            "skillSection.skills.2": "Exploitation de base de données en vue d'amélioration continue du produit",
            "skillSection.skills.3": "Mise en place de CI/CD & Integration d'outil tiers Firebase/ Digital Ocean / Google Cloud ",


            "study.iut.schoolName" : "Université de Montpellier",
            "study.iut.subHeader" : "BUT Informatique",
            "study.iut.duration" : "Sep 2022 - Août 2025",
            "study.iut.desc" : "Apprentissage des bonnes pratiques en matière de développement & Gestion de projet",


            "workExperience.vstrivia.role": "Entrepreneur",
            "workExperience.vstrivia.company": "VS Trivia",
            "workExperience.vstrivia.date": "Jan 2023 – Actuellement",
            "workExperience.vstrivia.desc": "Réalisation complètes des application iPhone/Android (design, programmation, reseau). Mis en place d’une API sous Python et gestion des sockets. Mis en place d’un dashboard administratif sous React. Elaboration de l’offre payante avec IAP.",

            "workExperience.cd30.role": "Chargé de mission en alternance pour la transition numérique",
            "workExperience.cd30.company": "Gard Departmental Council",
            "workExperience.cd30.date": "Sep 2023 – Août 2025",
            "workExperience.cd30.desc": "Lors de cette mission, j’ai été chargé de gérer le déploiement au niveau départemental d’une solution informatique développe par le CNSA visant à homogénéiser le processus d’aide aux personnes en pertes d’autonomie. J’ai aussi pu mettre en place des outils visant à l’aide à la prise de décision à l’aide de Power BI.",

            "contactInfo.title":"Me contacter ☎️",
            "contactInfo.subTitle": "Vous souhaitez discuter d'un projet ou simplement dire bonjour ? Ma boîte de réception est ouverte à tous.",
        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
        resources,
        fallbackLng: "en",
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;