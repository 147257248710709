/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation";
import i18next from "i18next"; // Rename to your file name for custom animation

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "Baptiste Lespinasse",
  title: i18next.t("greeting.title"),
  subTitle: emoji(
      i18next.t("greeting.subTitle")
  ),
  resumeLink:
    "https://www.linkedin.com/in/baptiste-lespinasse/", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/baptistelsp",
  linkedin: "https://www.linkedin.com/in/baptiste-lespinasse/",
  gmail: "baptiste@bales.cc",
  gitlab: "",
  facebook: "",
  medium: "",
  stackoverflow: "",
  // Instagram, Twitter and Kaggle are also supported in the links!
  // To customize icons and social links, tweak src/components/SocialMedia
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: i18next.t("skillSection.title"),
  subTitle: i18next.t("skillSection.subTitle"),
  skills: [
    emoji(
      "⚡ " + i18next.t("skillSection.skills.1")
    ),
    emoji("⚡ " + i18next.t("skillSection.skills.2")),
    emoji(
      "⚡ " + i18next.t("skillSection.skills.3")
    )
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "nodejs",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "swift",
      fontAwesomeClassname: "fab fa-swift"
    },
    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm"
    },
    {
      skillName: "sql-database",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "digital ocean",
      fontAwesomeClassname: "fa-brands fa-digital-ocean"
    },
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "docker",
      fontAwesomeClassname: "fab fa-docker"
    },
    {
      skillName: "kubernetes",
      fontAwesomeClassname: "fa-solid fa-cubes"
    },
    {
        skillName: "machine learning",
        fontAwesomeClassname: "fa-solid fa-robot"

    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: i18next.t("study.iut.schoolName"),
      logo: require("./assets/images/umontpellier.jpeg"),
      subHeader: i18next.t("study.iut.subHeader"),
      duration: i18next.t("study.iut.duration"),
      desc: i18next.t("study.iut.desc"),
      descBullets: [
      ]
    },
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Python",
      progressPercentage: "90%"
    },
    {
      Stack: "Data Engineering",
      progressPercentage: "70%"
    },
    {
      Stack: "Machine learning",
      progressPercentage: "60%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: i18next.t("workExperience.vstrivia.role"),
      company: "VS Trivia",
      url: "https://vstrivia.app/",
      companylogo: require("./assets/images/vsTrivia.jpg"),
      date: i18next.t("workExperience.vstrivia.date"),
      desc: i18next.t("workExperience.vstrivia.desc")
    },
    {
      role: i18next.t("workExperience.cd30.role"),
      company: i18next.t("workExperience.cd30.company"),
      url: "https://gard.fr/",
      companylogo: require("./assets/images/cd30.png"),
      date: i18next.t("workExperience.cd30.date"),
      desc: i18next.t("workExperience.cd30.desc")
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: false // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Projets archivés",
  subtitle: "Travaux personnels ayant atteint un état stable et ayant été archivés.",
  projects: [
    {
      image: require("./assets/images/lund.png"),
      projectName: "Lund - Agenda Scolaire",
      projectDesc: "Lund était une application Android innovante, développée en Java, ciblant les étudiants pour la gestion de leur agenda scolaire. Elle offrait de multiples fonctionnalités, dont la plus appréciée était l'accès aux devoirs et notes depuis un téléphone ou un ordinateur. Elle permettait également de gérer son emploi du temps avec des semaines A/B, d'organiser les photos de cours via une interface spécifique, et synchronisait toutes les données avec un serveur monolithique. Cependant, le projet s'est terminé en raison de revenus insuffisants pour couvrir les coûts de stockage de données et de maintenance. Malgré cela, le projet a permis à son créateur de développer des compétences significatives, ayant attiré 10 000 utilisateurs. Bien que l'application ne soit plus disponible sur le Play Store, elle reste accessible sur ApkPure, bien que l'expérience utilisateur puisse être réduite à cause de la fermeture des serveurs.",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://apkpure.net/fr/lund-school-planner/io.upown.lund"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/helio.png"),
      projectName: "Helio",
      projectDesc: "En tant que développeur, le projet Helio implique la création d'une application de développement personnel offrant des articles et citations hebdomadaires pour motiver les utilisateurs. L'objectif est d'améliorer la productivité, la santé et l'autonomie personnelle, avec un contenu inspirant montrant comment des techniques simples peuvent mener au succès. Les défis principaux incluent le maintien d'un contenu pertinent et la garantie d'une expérience utilisateur optimale.",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://apkpure.net/fr/helio-d%C3%A9veloppement-personnel/io.upown.helio"
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),
  subtitle:
    "Achievements, Certifications, Award Letters and Some Cool Stuff that I have done !",

  achievementsCards: [
    {
      title: "Google Code-In Finalist",
      subtitle:
        "First Pakistani to be selected as Google Code-in Finalist from 4000 students from 77 different countries.",
      image: require("./assets/images/codeInLogo.webp"),
      imageAlt: "Google Code-In Logo",
      footerLink: [
        {
          name: "Certification",
          url: "https://drive.google.com/file/d/0B7kazrtMwm5dYkVvNjdNWjNybWJrbndFSHpNY2NFV1p4YmU0/view?usp=sharing"
        },
        {
          name: "Award Letter",
          url: "https://drive.google.com/file/d/0B7kazrtMwm5dekxBTW5hQkg2WXUyR3QzQmR0VERiLXlGRVdF/view?usp=sharing"
        },
        {
          name: "Google Code-in Blog",
          url: "https://opensource.googleblog.com/2019/01/google-code-in-2018-winners.html"
        }
      ]
    },
    {
      title: "Google Assistant Action",
      subtitle:
        "Developed a Google Assistant Action JavaScript Guru that is available on 2 Billion devices world wide.",
      image: require("./assets/images/googleAssistantLogo.webp"),
      imageAlt: "Google Assistant Action Logo",
      footerLink: [
        {
          name: "View Google Assistant Action",
          url: "https://assistant.google.com/services/a/uid/000000100ee688ee?hl=en"
        }
      ]
    },

    {
      title: "PWA Web App Developer",
      subtitle: "Completed Certifcation from SMIT for PWA Web App Development",
      image: require("./assets/images/pwaLogo.webp"),
      imageAlt: "PWA Logo",
      footerLink: [
        {name: "Certification", url: ""},
        {
          name: "Final Project",
          url: "https://pakistan-olx-1.firebaseapp.com/"
        }
      ]
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Foire aux questions",
  subtitle:
    "Des réponses concises à des questions fréquemment posées.",
  blogs: [
    {
      title: "Que se passera-t-il à la fin de vos études à l'IUT ?",
      description:
        "Après cette formation, je souhaite continuer mes études en Master (en alternance), dans le but de me spécialiser dans l'analuse de données et l'intelligence artificielle. Je souhaite également continuer à développer des projets personnels, notamment dans le domaine de la santé et de l'éducation.",
    },
    {
      title: "Quels secteur d'activité vous intéresse le plus ?",
      description:
        "Je souhaite à terme travailler dans la fonction publique dans les fonctions IT. Cette institution est en accord avec mes valeurs et l'ambiance de travail y est agréable"
    },
    {
      title: "Quels projet à venir dans l'entrepreunariat ?",
      description:
          "J'ai pour ambition de développer au maximum VS Trivia en développant une communauté de passioné autour de ce projet. Je suis d'ailleurs bénéficiaire du Statut National d'Etudiant Entrepreuneur (SNEE)."
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(
    "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅"
  ),

  talks: [
    {
      title: "Build Actions For Google Assistant",
      subtitle: "Codelab at GDG DevFest Karachi 2019",
      slides_url: "https://bit.ly/saadpasta-slides",
      event_url: "https://www.facebook.com/events/2339906106275053/"
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo"
  ],
  display: false // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji(i18next.t("contactInfo.title")),
  subtitle: i18next.t("contactInfo.subTitle"),
  email_address: ""
};

// Twitter Section

const twitterDetails = {
  userName: "twitter", //Replace "twitter" with your twitter username without @
  display: false // Set true to display this section, defaults to false
};

const isHireable = false; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
  isHireable
};
